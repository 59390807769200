<script>
import { useGcCinema } from '/~/extensions/gift-cards-cinema/composables'
import EwCatalogProcessor from '/~/components/ewallet/catalog/ew-catalog-processor.vue'
import GcTile from './gc-tile.vue'

export default {
  name: 'gift-cards-cinema-catalog-bupa',
  components: {
    EwCatalogProcessor,
    GcTile,
  },
  props: {
    slots: {
      type: Array,
      default: () => [],
    },
    groupBy: {
      type: String,
      default: '',
    },
  },
  setup() {
    const { gcCinemaAlgoliaWorker } = useGcCinema()

    return {
      algolia: gcCinemaAlgoliaWorker,
    }
  },
}
</script>

<template>
  <ew-catalog-processor
    tile-class="hover:-translate-y-[5px]"
    :processor="algolia"
    :columns="{
      xxs: 2,
      sm: 3,
      lg: 4,
    }"
    :group-by="groupBy"
  >
    <template #empty>
      <p>No Result found</p>
      <p>Please try another category</p>
    </template>
    <template #tile="{ tile }">
      <gc-tile :tile="tile" />
    </template>
    <template v-for="slot in slots" :slot="slot">
      <slot :name="slot" />
    </template>
  </ew-catalog-processor>
</template>
